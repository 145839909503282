import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform(value: number, precision=0): unknown {
    if(value===null){
      return null;
    }else{
      let  mult=1;
      for (let i = 0; i < precision; i++) {
        mult *= 10;
      }
      if(precision===0){
        return Math.round(value);
      }else{
        return Math.round(value*mult)/mult;
      }
    }
  }

}
